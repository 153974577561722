/**
 * @package     Wordpress.Site
 * @subpackage  Templates.NoName
 *
 * @copyright   Copyright (C) 2020 NoName. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */

import(/* webpackPrefetch: true */ "../utils/header.js");
import(/* webpackPrefetch: true */ "../utils/footer.js");
import { fontAwesomeLoad } from "root/utils/fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
fontAwesomeLoad(".fa-times-circle", faTimesCircle);
fontAwesomeLoad(".fa-check", faCheck);
import JQuery from "jquery";
window.jQuery = JQuery;

jQuery(document).ready(function($) {
  function performSearch(searchQuery) {
      $.ajax({
          url: '/wp-admin/admin-ajax.php',
          type: 'GET',
          data: {
              action: 'search_posts',
              s: searchQuery
          },
          success: function(response) {
              $('#search-results').html(response); 


              var newUrl = '/search?search=' + encodeURIComponent(searchQuery);
              window.history.pushState({path: newUrl}, '', newUrl);

            
              $('#search').val(searchQuery);
              $('#search').attr('placeholder', searchQuery); 
          },
          error: function() {
              $('#search-results').html('<p>There was an error processing your request.</p>');
          }
      });
  }

  // On form submit, trigger the search
  $('#searchform').on('submit', function(e) {
      e.preventDefault(); 
      var searchQuery = $('#search').val(); 
      performSearch(searchQuery);
  });

  $(window).on('load', function() {
      var urlParams = new URLSearchParams(window.location.search);
      var searchQuery = urlParams.get('search'); 
      if (searchQuery) {
          performSearch(searchQuery);
          $('#search').attr('placeholder', searchQuery); 
      }
  });

  // Handle the back/forward navigation
  window.onpopstate = function(event) {
      if (event.state && event.state.path) {
          var urlParams = new URLSearchParams(event.state.path.split('?')[1]);
          var searchQuery = urlParams.get('search');
          if (searchQuery) {
              $('#search').val(searchQuery); 
              $('#search').attr('placeholder', searchQuery); 
              performSearch(searchQuery);
          }
      }
  };
});